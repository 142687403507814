<template>
	<!-- Wrap your dialog in a `TransitionRoot`. -->
	<TransitionRoot
		:show="isOpen"
		as="template"
	>
		<Dialog
			as="div"
			class="relative z-[9999]"
			@close="handleOutsideClick"
		>
			<!-- Wrap your backdrop in a `TransitionChild`. -->
			<TransitionChild
				as="template"
				enter="ease-in-out duration-200"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in-out duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 z-40 transition-opacity bg-black/60" />
			</TransitionChild>
			<!-- Full-screen container to center the panel -->
			<div class="fixed inset-0 z-50 flex items-center justify-center w-screen max-h-screen px-4 overflow-y-auto lg:px-0 md:ml-32">
				<!-- Wrap your panel in a `TransitionChild`. -->
				<TransitionChild
					as="div"
					enter="duration-300 ease-out"
					enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100"
					leave="duration-200 ease-in"
					leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95"
					class="w-full"
				>
					<!-- The actual dialog panel -->
					<DialogPanel
						:class="[
							'relative mx-auto max-h-screen px-2 py-6 bg-white rounded-xl shadow-xl isolate overflow-hidden dark:bg-mx-green-700',
							maxWidth
						]"
					>
						<!-- 'x' close button -->
						<button
							v-if="!hideClose"
							type="button"
							class="absolute top-0 right-0 p-2 m-0.5 text-mx-gray-400 rounded-full hover:text-mx-gray-500 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 focus:outline-none"
							@click="emitCancel"
						>
							<span class="sr-only">
								Close
							</span>
							<svg
								class="w-3 h-3"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<div :class="maxHeight">
							<!-- default slot for the dialog panel -->
							<slot
								name="default"
								:close-modal="closeModal"
								:emit-cancel="emitCancel"
								:emit-confirm="emitConfirm"
							>
								<DialogTitle
									v-if="!hideTitle"
									:class="titleClasses"
								>
									<slot name="title">
										<span class="text-3xl text-transparent bg-clip-text bg-text-gradient">
											{{ title }}
										</span>
									</slot>
								</DialogTitle>
								<div class="px-4 max-h-[70vh] overflow-x-hidden overflow-y-auto">
									<!-- body slot for the dialog panel -->
									<slot
										name="body"
										:close-modal="closeModal"
										:emit-cancel="emitCancel"
										:emit-confirm="emitConfirm"
									>
										<div class="flex flex-col gap-4">
											<div :class="descriptionClasses">
												{{ description }}
											</div>
										</div>
									</slot>
								</div>
								<!-- footer slot for the dialog panel -->
								<slot
									v-if="!hideFooter"
									name="footer"
									:close-modal="closeModal"
									:emit-cancel="emitCancel"
									:emit-confirm="emitConfirm"
								>
									<div class="flex flex-row items-center justify-end gap-4 px-4 pt-4">
										<!-- TODO i18n -->
										<FormButton
											v-if="!hideCancel"
											:button-width="cancelWidth"
											button-text="Cancel"
											variant="secondary-foreground"
											:disabled="false"
											@click="emitCancel"
										/>
										<!-- TODO i18n -->
										<FormButton
											v-if="!hideConfirm"
											button-text="Confirm"
											:button-width="confirmWidth"
											variant="primary"
											:is-loading="requestLoading"
											submit
											:disabled="confirmDisabled"
											@submit="emitConfirm"
										/>
									</div>
								</slot>
							</slot>
						</div>
					</DialogPanel>
				</Transitionchild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/vue'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	title: {
		type: String,
		default: 'Modal Title'
	},
	description: {
		type: String,
		default: ''
	},
	confirmText: {
		type: String,
		default: 'Confirm'
	},
	cancelText: {
		type: String,
		default: 'Cancel'
	},
	overrideControls: {
		type: Boolean,
		default: false
	},
	isOpen: {
		type: Boolean,
		default: true
	},
	titleClasses: {
		type: String,
		default: 'text-lg font-bold px-4'
	},
	descriptionClasses: {
		type: String,
		default: 'text-sm text-mx-gray-500 dark:text-mx-green-600'
	},
	confirmWidth: {
		type: String,
		default: 'w-32'
	},
	cancelWidth: {
		type: String,
		default: 'w-32'
	},
	hideFooter: {
		type: Boolean,
		default: false
	},
	hideTitle: {
		type: Boolean,
		default: false
	},
	hideCancel: {
		type: Boolean,
		default: false
	},
	hideConfirm: {
		type: Boolean,
		default: false
	},
	hideClose: {
		type: Boolean,
		default: false
	},
	maxWidth: {
		type: String,
		default: 'max-w-lg'
	},
	maxHeight: {
		type: String,
		default: 'max-h-[90vh]'
	},
	confirmDisabled: {
		type: Boolean,
		default: false
	},
	preventOutsideClickClose: {
		type: Boolean,
		default: false
	},
	requestLoading: {
		type: Boolean,
		default: false
	}
})

const { overrideControls, confirmDisabled, preventOutsideClickClose } = toRefs(props)

const emit = defineEmits([
	'onConfirm',
	'onCancel',
	'onClose'
])

const handleOutsideClick = () => {
	if (!preventOutsideClickClose.value) {
		emitCancel()
	}
}

const emitConfirm = () => {
	if (!confirmDisabled.value) {
		emit('onConfirm')
		if (!overrideControls.value) {
			closeModal()
		}
	}
}

const emitCancel = () => {
	emit('onCancel')
	if (!overrideControls.value) {
		closeModal()
	}
}

const closeModal = () => {
	emit('onClose')
}
</script>

<style scoped>
/* WebKit Browsers */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #6A6A6A;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ADADAD;
}

/* Remove scrollbar buttons */
::-webkit-scrollbar-button {
	display: none;
}

/* Firefox Browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #6A6A6A transparent;
}
</style>
